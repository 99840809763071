<template>
  <div class="search-container">
    <app-header></app-header>
    <div class="top">
      <div class="inp">
        <input
          v-model="search"
          placeholder="请输入歌曲/歌单"
          @keydown.enter="searchList"
        /><span @click="searchList"
          ><img src="../../src/assets/search.png"
        /></span>
      </div>
    </div>
    <div class="main">
      <com-tab
        t="搜索结果"
        :buttons="['歌曲', '歌单']"
        :currentIndex.sync="currentIndex"
      ></com-tab>
      <com-button
        v-if="currentIndex === 0"
        type="allPlay"
        style="margin-bottom: 20px"
        :icon="require('../assets/play.png')"
        @click="playAll"
        >全部播放</com-button
      >
      <table-single
        v-loading="loading"
        v-if="currentIndex === 0"
        :list="singleList"
        :songs="true"
        :total="singleTotal"
        :page-size="pageSize"
        @currentPageChange="singlePageChang"
        :currentPage="singleCurrentPage"
      ></table-single>

      <table-songs
        v-loading="loading"
        v-if="currentIndex === 1"
        :list="songsList"
        :songs="true"
        :indexShow="false"
        :total="songsTotal"
        :page-size="pageSize"
        @currentPageChange="songsPageChang"
        :currentPage="songsCurrentPage"
        :hideSingleNum="false"
      ></table-songs>
    </div>
    <app-footer></app-footer>
  </div>
</template>
<script>
import appHeader from "@/components/app-header";
import appFooter from "@/components/app-footer";
import comTab from "@/components/com/com-tab";
import comButton from "@/components/com/com-button";
import tableSingle from "@/components/table/table-single";
import tableSongs from "@/components/table/table-songs";
import { validatenull } from "@/util/validate";
import { Single, Songs } from "@/class/music";
export default {
  components: {
    appHeader,
    appFooter,
    tableSingle,
    tableSongs,
    comTab,
    comButton,
  },
  computed: {
    currentList() {
      return this.list.slice(
        (this.currentPage - 1) * this.pageSize,
        this.currentPage * this.pageSize
      );
    },
    searchValue() {
      return this.$route.query.search;
    },
    send() {
      let { search, form } = this.$route.query;
      return { search, form };
    },
  },
  watch: {
    send: {
      immediate: true,
      handler(d) {
        this.resetData();
        if (!validatenull(d.search)) {
          this.search = d.search;
          // console.log(d.form);
          ~~d.form === 0
            ? this.sendGetList(d.search, "music")
            : this.sendGetList(d.search, "list");
        }
      },
    },
    "$route.query.form": {
      immediate: true,
      handler(v) {
        v
          ? (this.currentIndex = ~~v)
          : this.$router.push({ query: { ...this.$route.query, form: "0" } });
      },
    },
    currentIndex(v) {
      this.$router.push({ query: { ...this.$route.query, form: v } });
    },
  },
  data() {
    return {
      currentIndex: 0,
      singleList: [],
      songsList: [],
      songsCurrentPage: 1,
      singleCurrentPage: 1,
      pageSize: 10,
      search: "",
      songsTotal: 0,
      singleTotal: 0,
      loading: false,
      alertTotal: false,
    };
  },
  methods: {
    sendGetList(name, type) {
      this.loading = true;
      let page =
        type === "music" ? this.singleCurrentPage : this.songsCurrentPage;
      let filter = type === "music" ? `type: ${type},shelves:1` : `type: ${type}`
      this.$api
        .byNameSearchList({
          query: `name:${name}`,
          filter: filter,
          page: page,
          size: this.pageSize,
        })
        .then((resolve) => {
          this.loading = false;
          let total = resolve.data.total;
          this.alertTotal && this.$message.success(`已搜索出${total}条数据`);
          this.alertTotal = false;
          if (type === "music") {
            this.singleTotal = total;
            this.singleList = resolve.data.data.map((item) => new Single(item));
          } else {
            this.songsTotal = total;

            this.songsList = resolve.data.data.map((item) => {
              item.timer = item.times;
              return new Songs(item);
            });
          }
        })
        .catch(() => (this.loading = false));
    },
    singlePageChang(currentPage) {
      this.singleCurrentPage = currentPage;
      console.log(currentPage);
      this.sendGetList(this.searchValue, "music");
    },
    songsPageChang(currentPage) {
      this.songsCurrentPage = currentPage;
      this.sendGetList(this.searchValue, "list");
    },
    playAll() {
      this.$store.commit("ADD_PLAYLIST", this.singleList);
    },
    resetData() {
      this.songsCurrentPage = 1;
      this.singleCurrentPage = 1;
    },
    searchList() {
      let search = this.search;
      if (validatenull(search)) {
        this.$message.error("请输入歌曲/歌单");
        return;
      }
      this.alertTotal = true;
      this.$router.push({
        query: {
          ...this.$route.query,
          search: search,
          time: new Date().getTime(),
        },
      });
    },
  },
};
</script>
<style scoped lang="scss">
@import "../../src/style/css.scss";
.top {
  height: 264px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url("../assets/bg2.jpg");
  background-size: 100%;
  min-width: $containerWidth;
  .inp {
    width: 686px;
    height: 55px;
    background: #f2f2f2;
    border-radius: 2px;
    padding: 0 19px;
    display: flex;
    align-items: center;
    > input {
      @extend .app-clean-input;
      width: 0;
      flex-grow: 1;
      height: 50px;
      line-height: 50px;
      font-size: 16px;
      color: #333333;
      background: #f2f2f2;
    }
    > span {
      height: 100%;
      display: flex;
      align-items: center;
      cursor: pointer;
      img {
        width: 20px;
      }
    }
  }
}
.main {
  width: $containerWidth;
  margin: auto;
  padding-top: 20px;
}
</style>
