<template>
  <div style="width: 100%" class="com-list-container">
    <el-table
      :row-style="{ height: '75px' }"
      :header-row-style="{ height: '50px' }"
      :header-cell-style="{
        fontSize: '16px',
        color: '#696B6F',
        fontFamily: 'PingFangSC-Medium, PingFang SC;',
      }"
      :data="list"
      :row-class-name="tableRowClassName"
      :border="false"
      style="width: 100%"
    >
      <el-table-column
        v-if="selection"
        type="selection"
        align="right"
        width="50"
      >
      </el-table-column>
      <el-table-column v-if="indexShow" label="序号" align="center" width="70">
        <template slot-scope="scope">
          <div v-if="ranking && scope.$index + startIndex === 1">
            <img style="width: 30px" src="../../assets/home/pm1.png" />
          </div>
          <div v-else-if="ranking && scope.$index + startIndex === 2">
            <img style="width: 30px" src="../../assets/home/pm2.png" />
          </div>
          <div v-else-if="ranking && scope.$index + startIndex === 3">
            <img style="width: 30px" src="../../assets/home/pm3.png" />
          </div>
          <div v-else>{{ scope.$index + startIndex }}</div>
        </template>
      </el-table-column>

      <!--      <el-table-column v-if="ranking" prop="ranking" width="80">-->
      <!--        <template slot-scope="scope">-->
      <!--          <div style="width: 50px; height: 50px; display: flex">-->
      <!--            <img :src="scope.row.image" style="width: 100%" />-->
      <!--          </div>-->
      <!--        </template>-->
      <!--      </el-table-column>-->

      <el-table-column v-if="image" prop="image" width="80">
        <template slot-scope="scope">
          <div style="width: 50px; height: 50px; display: flex">
            <img :src="scope.row.image" style="width: 100%" />
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="name" label="歌单">
        <template slot-scope="scope">
          <div style="display: flex; align-items: center">
            <div
              v-if="
                ranking && scope.$index + startIndex <= 3 && scope.row.image
              "
              style="
                width: 50px;
                height: 50px;
                display: flex;
                margin-right: 10px;
              "
            >
              <img style="width: 100%" :src="scope.row.image" />
            </div>
            <div
              class="hover"
              @click="
                $router.push(
                  `/songsDetail/${scope.row.id}?name=${scope.row.name}`
                )
              "
            >
              {{ scope.row.name }}
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="user" label="创建人"> </el-table-column>

      <el-table-column
        prop="playNum"
        label="歌曲数"
        v-if="hideSingleNum"
      ></el-table-column>

      <el-table-column prop="listenNum" width="110" label="播放量">
      </el-table-column>
    </el-table>

    <com-page
      :total="total"
      layout="prev, pager, next"
      :page-size="pageSize"
      :currentPage="currentPage"
      @change="pageChange"
    ></com-page>
  </div>
</template>
<script>
import comPage from "@/components/com/com-page";
export default {
  components: {
    comPage,
  },
  data() {
    return {};
  },
  watch: {
    // list: {
    //   immediate: true,
    //   handler() {
    //     this.list.forEach((item, index) => (item._index = index + 1));
    //   },
    // },
  },
  computed: {
    startIndex() {
      let { pageSize, currentPage } = this;
      return 1 + pageSize * (currentPage - 1);
    },
    currentList() {
      return this.list.slice(
        (this.currentPage - 1) * this.pageSize,
        this.currentPage * this.pageSize
      );
    },
  },
  props: {
    songs: {
      type: Boolean,
      default: false,
    },
    image: {
      type: Boolean,
      default: false,
    },
    list: Array,
    ranking: {
      type: Boolean,
      default: false,
    },
    indexShow: {
      type: Boolean,
      default: true,
    },
    pageSize: {
      type: Number,
      default: 30,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    total: Number,
    selection: {
      type: Boolean,
      default: false,
    },
    hideSingleNum: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    tableRowClassName({ rowIndex }) {
      return rowIndex % 2 ? "table-bg1" : "table-bg2";
    },
    pageChange(currentPage) {
      this.$emit("currentPageChange", currentPage);
    },
  },
};
</script>
<style lang="scss">
@import "../../style/list";
.com-list-container {
  @extend .com-list;
}
</style>
